body > #root> .loading-content {
  height: 100vh;
}
.loading-content  {

  display: flex;
  justify-content: center;
  background-color: var(--bg-theme);
  align-items: center;
}
.loading-content > .icon {
  -webkit-user-drag: none;
  margin-bottom: 5px;
  user-select: none;
}
.loading-content > .spinner {
  cursor: progress;
  position: absolute;
  width: 100%;
  height: 100%;
}
.loading-content > .spinner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10001;
  overflow: hidden;

}
.loading-content > .spinner::after {
  box-sizing: border-box;
  z-index: 10002;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  border: 5px solid var(--spinner-theme);
  border-top: 5px solid #54c2f0;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;
}


@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}