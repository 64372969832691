.generator {
    display: flex;
    font-family: "Open Sans",sans-serif;
    color: var(--text-theme);
    
    background-color: var(--bg-theme);
}
.generator-content {
    display: flex;
    align-items: center;
    flex-direction: column;

    height: 100vh;
    width: 100%;
    
}
.generated-password-box  {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    height: 80px;
    -webkit-box-shadow: 1px 1px 8px -1px rgba(0,0,0,0.27); 
    box-shadow: 1px 1px 8px -1px rgba(0,0,0,0.27);
    border-radius: 5px;
    background-color: var(--bg-box-theme);


}
.use-generator-text {
    text-align: center;
}
.generator-config {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-box-theme);
    margin-top: 50px;
    width: 80%;
    height: 200px;
    -webkit-box-shadow: 1px 1px 8px -1px rgba(0,0,0,0.27); 
    box-shadow: 1px 1px 8px -1px rgba(0,0,0,0.27);
    border-radius: 5px;
}
.generator-config > .configure-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
 .generator-config > h2{ 
    align-self: center;
}
.password-length-box > p {
    font-weight: 600;
    margin-left: 50Px;
}


 .generated-password-box > .password-field {
    display: flex;
    width: 85%;
    height: 85%;
    flex-direction: row;
    justify-content: space-between;

}
.generated-password-box > .password-field > input{
    font-family: "Open Sans",sans-serif;
    font-weight: 600;
    font-size: 30px;
    color: var(--text-theme);
    background-color: var(--bg-box-theme);

    width: 80%;
    border:none;
    outline: none;
}
.password-field > .buttons {
    display: flex;
    flex-direction: row;
    width: 15%;
    align-items: center;
}
.password-field > .buttons > .button {
    font-size: 30px;
    color: #757575;
    margin-right: 20px;
}
.password-field > .buttons > .fa-sync:active {
    animation: rotate 0.1s linear;
}
.password-field > .buttons > .button:hover {
    cursor: pointer;
} 
.use-generator-text {
    font-size: 20px;
    font-weight: 600;
}
.generated-password-box > .password-strength-bar {
    width: 100%;
    height: 15%;

}
.generated-password-box > .password-strength-bar > .progress-bar {
    background-color: #0BF06B;
    width: 100%;
    height: 100%;
    transition: width 0.5s;
    border-radius: 3px;
}
.password-attributes-box {
    display: flex;
    flex-direction: row;
    margin-right: 100px;
}

.password-attributes-box > .box1 > .attribute, .password-attributes-box > .box2 > .attribute {
    display: flex;
    flex-direction: row-reverse;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
}
.password-length-box > .MuiSlider-root {
    color: #54c2f0;
    height: 8px;
    margin-left: 50px;
    width: 200px;
}
.password-length-box > .MuiSlider-root  .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: var(--bg-box-theme);
    border: 2px solid currentColor;
    margin-top: -8px;
    margin-left: -12px;
}
.password-length-box > .MuiSlider-root .MuiSlider-thumb:focus,.password-length-box > .MuiSlider-root .MuiSlider-thumb:hover, .password-length-box > .MuiSlider-root .MuiSlider-thumb:active {
    box-shadow: inherit;
}
.password-length-box > .MuiSlider-root .MuiSlider-valueLabel {
    left: calc(-50% + 4px);
}
.password-length-box > .MuiSlider-root .MuiSlider-track {
    height: 8px;
    border-radius: 4px;
}
.password-length-box > .MuiSlider-root .MuiSlider-rail	 {
    height: 8px;
    border-radius: 4px;
}

@-webkit-keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
  @media all and (max-width: 800px) {
    .generator-config > .configure-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .generator-config {
        height: 500px;

    }
    .password-attributes-box, .password-length-box > .MuiSlider-root, .password-length-box > p  {
        margin: 0;
    }


}
  @media all and (max-width: 600px) {
    .generator-content > h1 {
        text-align: center;
        margin-top: 50px;
        font-size: 23px;
    }

    .generator-config > h2 {
        font-size: 23px;
        text-align: center;
    }
 
    .use-generator-text {
        font-size: 15px;
    }
    .password-field > .buttons > .button { 
        margin: 5px;
        font-size: 25px;
    }
    .generated-password-box > .password-field, .generator-config,   .generated-password-box  {
        width: 95%;
    }
    .generated-password-box > .password-field > input{
        width: 100%;
        font-size: 20px;
    }

    .password-field > .buttons {
        width: 15%;
    }
  }

  @media all and (max-width: 400px) {
    .password-field > .buttons {
        width: 25%;
    }
    .generated-password-box > .password-field > input{
        font-size: 17px;
    }
  }
