body > #root> .auth-register {
    height: 100vh;
  }
#nav-logo-register {
    position: absolute;
    left: 15px;
    user-select: none;
    -webkit-user-drag: none;
}
#swal2-title,#swal2-content {
    color: var(--text-theme);
} 
.auth-register {
    display: flex;
    flex-direction: row;
    font-family:  "Open Sans", sans-serif;
    background-color: var(--bg-theme);

}
.welcome-register {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: var(--blue-bg-theme);
    width: 55%;
}
.welcome-register > h1, .welcome-register > h3  {
    color: var(--text-theme)
}
.icon {
    user-select: none;
    -webkit-user-drag: none;
}
.welcome-register > .welcome {
    font-size: 30Px;
}
.welcome-register > .login-btn {
        cursor: pointer;
        border: 2px #54c2f0 solid;
        border-radius: 5px;
        background-color: var(--bg-2-theme);
        color:#54c2f0;
        font-family: "Open sans",sans-serif;
        font-size: 20px;
        font-weight: 600;
        padding: 10px ;
        transition: background-color 0.5s, color 0.5s;
        width: 250px;
        text-decoration: none;
}
.welcome-register > .login-btn:hover {
    background-color: rgba(223,223,223,0.2);
    transition: background-color 0.5s, color 0.5s;

}
.register-form {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 45%;
}

.register-form > #nav-logo-reg-responsive {
    display: none;
}
.register-form > h2 {
    margin-bottom: 50px;
    color: var(--text-theme)
}
.register-form > .resend-email {
    border: none;
    background: transparent;
    outline: none;
    color: #045CF0;
    font-size: 15px;
}
.register-form > .resend-email:hover {
    cursor: pointer;
}
.register-form > .resend-email:disabled {
    color: #B3B3B3;
}
.register-form > .resend-email:disabled:hover {
    cursor: not-allowed;
}
.register-form > .confirmation-email-sent, .register-form > .email {
    color: var(--text-theme)
}
.register-form > .field, .register-form > .name-fields >  .field {
    
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family:  "Open Sans", sans-serif;
    background-color: var(--field-bg-theme);

    width: 350px;
    height: 35px;
    border-radius: 5px;
    
}

.register-form > .field > i, .register-form > .name-fields  i {
    color: var(--text-theme)
}
.register-form > .name-fields {
    display: flex;
    flex-direction: row;
}
.register-form > .name-fields >  .name-field {
    margin-left: 3px;
    width: 175px;
}
.register-form > .field > #email-input {
    margin-right: 25px;
}

.register-form > .field > input,  .register-form > .name-fields input{
    width: 100%;
    height: 35px;
    border:none;
    outline: none;
    font-size: 17px;
    color: var(--text-theme);
    background: transparent;
    text-align: center;
}
.register-form > .field input:-webkit-autofill, .register-form > .name-fields input:-webkit-autofill {
    border: none;
    border-radius: .3rem;
    color: var(--text-theme);
    background: var(--field-bg-theme);
    caret-color: var(--text-theme);
    -webkit-text-fill-color: var(--text-theme);
    background-clip: text; 
    box-shadow: 0 0 0 50px var(--field-bg-theme) inset; 
    max-height: 33px;
    border-radius: 0px;
}
.register-form > .already-account {
    display: none;
    margin-top: 15px;
    color: var(--text-theme);
    text-decoration: none;
    white-space: nowrap;

}
.register-form > .already-account:hover {
    text-decoration: underline var(--text-theme);
}
.register-form > .register-btn {
    cursor: pointer;
    font-family:  "Open Sans", sans-serif;
    margin-top: 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: #54c2f0;
    color:white;
    font-family: "Open sans",sans-serif;
    font-size: 20px;
    font-weight: 600;
    padding: 10px ;
    transition: all 0.5s;
    width: 250px;
}
.register-form > .register-btn:hover {
    background-color: #4CB1DC;
    transition: all 0.5s;
}
.field-icon {
    margin-left: 5px;
}

body > #root > .auth-register > .welcome-register {
    height: 100vh;
  }

  @media all and (max-width: 1080px) {
    .welcome-register {
        display: none;
    }

    .auth-register { 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #nav-logo-register {
        display: none;
    }
    .register-form > #nav-logo-reg-responsive {
        display: inline;
    }
    .register-form > .already-account {
        display: inline;
    }

  }
  @media all and (max-width: 370px) {
    .register-form > .already-account {
        font-size: 13px;
    }
    .register-form > h2 {
        font-size: 19px;

    }
    .register-form > .field > input, .register-form > .field {
        font-size: 15px;
        width: 275px;
    }
    .register-form > .name-fields >  .name-field > input {
        font-size: 15px;

    }
    .register-form > .name-fields >  .name-field {

        width: 136px;
    }

    .register-form > .register-btn {
        width: 230px;
    }

  }