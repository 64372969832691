.dashboard-nav {
    display: flex;
    flex-direction: column;
    font-family: "Open Sans", sans-serif;
    background-color: var(--bg-nav-theme);
    width: 450px;
    height: 100vh;
    transition: width 0.5s ease-in-out;
    
}
.nav-closed {
    transition: width 0.5s ease-in-out;
    width: 65px;


}

.dashboard-nav > .top-buttons {
    display: flex;
    color: var(--text-theme);
    justify-content: space-between;
    
}
.dashboard-nav > .top-buttons  >.top-button {

    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 30px;
    font-weight: 400;
}

.dashboard-nav > .top-buttons  >.top-button:hover{
    cursor: pointer;
}

.dashboard-nav >.open-nav-button:hover {
    cursor: pointer;
}
.dashboard-nav > .nav-buttons  {
    display: flex;
    flex-direction: column;
}
.dashboard-nav > .nav-buttons > .nav-button,  .responsive-nav-ul > .nav-button  {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    text-decoration: none;
    font-weight: 600;
    font-size: 25px;
    padding: 15px 0px 15px 0px;
    transition: all 0.5s;
    overflow: hidden;
    color: var(--text-theme);
}


.dashboard-nav > .nav-buttons > .nav-button > .far {
    margin-left: 20px;
    margin-right: 20px;
}
.responsive-nav-ul > .nav-button > .far {
    margin-right: 20px;

}
.dashboard-nav > .nav-buttons > .nav-button:hover{
    transition: all 0.5s;
    background-color: rgba(177,177,177,0.2);
}
.dashboard-nav > .nav-buttons > .current-link {
    background-color: rgba(177,177,177,0.2);
    color: var(--current-nav-theme);
}
.dashboard-nav > .nav-buttons {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}



.responsive-btn {
    width: 2rem;
    height: 2rem;
    top: 25px;
    right: 65px;
    z-index: 20;
    display: none;
    
}
.responsive-btn > div{
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    background-color: var(--text-theme);
  }

@media all and (max-width: 1280px) {
    .dashboard-nav {
        display: none;
    }
  
    .responsive-btn {
        right: 20px;
        top: 30Px;
        display: flex;
        justify-content: space-around;
        flex-flow: column nowrap;
    }
    .responsive-nav-ul > .nav-button{
        display: flex;
        width: 100%;
        text-align: center;
    }
    .responsive-nav-ul {
        flex-flow: column nowrap;
        margin-top: 0;
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 300px;
        padding-top: 3.5rem;
        transition: transform 0.3s ease-in-out;
    }
      

}
@media all and (max-width: 350px) {
    .responsive-nav-ul {
        width: 100%;
    }
    .responsive-nav-ul > .nav-button{
        margin-left: 20px;
    }
}
.responsive-nav-ul {
    display: flex;
    font-family: "Open Sans", sans-serif;
    align-items: center;
    text-align: center;
    flex-flow: column nowrap;
    z-index: 10;
}
.responsive-navlink {
    text-decoration: none;
    color: white;
    margin-bottom: 15px;
    font-size: 25px;
    font-weight: 600;

}
.responsive-navlink:hover {
    text-decoration: underline 2px white;

}