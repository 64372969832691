.change-password-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 0.2s;
    visibility: hidden;
    opacity: 0;
  }
  #swal2-title,#swal2-content {
    color: var(--text-theme);
}
  
  .change-password-box {
    margin:  auto;
    padding: 20px;
    border-radius: 5px;
    width: 35%;
    position: relative;
    background-color: var(--bg-theme);
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
  }

  .change-password-box h2 {
    text-align: center;
    margin-top: 50px;
    color: var(--text-theme);

  }
  .change-password-box .close {
      outline: none;
      background: transparent;
      border: none;
    position: absolute;
    top: 0;
    left: 5px;
    transition: all 200ms;
    font-size: 45px;
    font-weight: bold;
    text-decoration: none;
    color: var(--text-theme);
  }
  .change-password-box .close:hover {
    cursor: pointer;
  }
  .change-password-box .content {
    max-height: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;

  }
  .change-password-box .content > .fields > p {
      text-align: center;
  }
  .change-password-box .content > .fields > .field{
    display: flex;
    flex-direction: column;
    border :1px var(--field-bg-theme) solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family:  "Open Sans", sans-serif;

    width: 350px;
    height: 35px;
    background-color: var(--field-bg-theme);
    border-radius: 5px;
    margin-bottom: 15px;
  }
  .change-password-box .content > .fields > .field > input{
    width: 100%;
    height: 100%;
    border:none;
    outline: none;
    font-size: 17px;
    background: transparent;
    text-align: center;
    color: var(--text-theme);
  }

  
  .change-password-box .content .continue-button {
    border: none;
    margin-top: 20px;
    outline: none;
    color: white;
    padding: 8px 15px 8px 15px;
    background-color: #54c2f0;
    border-radius: 5px;
    font-size: 20px;
    transition: background-color 0.5s;
  
  }
  .change-password-box .content .cancel-button {
    border: 1px solid #E1E1E1;
    margin-top: 20px;
    outline: none;
    color: var(--text-theme);
    padding: 8px 15px 8px 15px;
    border-radius: 5px;
    font-size: 20px;
    margin-left: 50px;
    background-color: var(--bg-theme);

    transition: background-color 0.5s;
  }
  .change-password-box .content .cancel-button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.5s;
    cursor: pointer;
  }
  .change-password-box .content .continue-button:hover {
    background-color: #54AAF0;
    transition: background-color 0.5s;
    cursor: pointer;
  }
  @media all and (max-width: 1053px){
    .box{
      width: 70%;
    }
    .change-password-box{
      width: 70%;
    }
  }
  @media all and (max-width: 524px) {
    .box{
      width: 95%;
    }
    .change-password-box{
      width: 95%;
    }
    .change-password-box .close {
      left: 0;
      top: 0;
    }
    .change-password-box .content > .fields > .field{
      width: 250px;
      height: 30px;

    }
    .change-password-box .content > .fields > .field > input{
        font-size: 14px;

    }
    .change-password-box h2 {
      font-size: 20px;
    }
  }