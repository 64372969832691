.password-item-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.password-item-box:hover {
  cursor: pointer;
}
.password-item-line {
  align-self: center;
  background-color: var(--line-theme);
  height: 0.5px;
  width: 95%;
}
.password-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  width: 95%;
}
.password-info,
.password-action {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.password-info > .website-icon {
  height: 50px;
  width: 50px;

  border: solid rgba(0, 0, 0, 0.1) 1px;
  user-select: none;
  -webkit-user-drag: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}
.password-info #no-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #54c2f0;
}
.password-info #no-img-box p {
  color: white;
  font-size: 25px;
  font-weight: 900;
}
.password-info > div {
  display: flex;
  flex-direction: column;
}
.password-info > div > .password-name {
  margin-left: 10px;
  font-weight: 600;
}
.password-info > div > .username-text {
  margin-top: -20px;
  margin-left: 15px;
  font-size: 13px;
}

.password-action > .browse-to-website-button,
.password-action > .edit-password-button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: transparent;
  height: 40px;
  width: 40px;
  text-decoration: none;
  margin-right: 5px;
  border: solid transparent 1px;
  border-radius: 5px;
}
.password-action > .browse-to-website-button > i,
.password-action > .edit-password-button > i {
  color: var(--text-theme);
}
.password-action > .browse-to-website-button:hover,
.password-action > .edit-password-button:hover {
  cursor: pointer;
  border: solid rgba(0, 0, 0, 0.1) 1px;
}
.password-action > .browse-to-website-button > .fa-external-link,
.password-action > .edit-password-button > .fa-edit {
  font-size: 25px;
}

@media all and (max-width: 400px) {
  .password-action > .browse-to-website-button,
  .password-action > .edit-password-button {
    margin: 0;
  }
  .password-info > div > .username-text {
    font-size: 10px;
  }
}
