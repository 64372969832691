.edit-password-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s;
  visibility: hidden;
  opacity: 0;
}

#swal2-title,
#swal2-content {
  color: var(--text-theme);
}
.edit-password-box {
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  width: 35%;
  position: relative;
  background-color: var(--bg-theme);
  top: 50%;
  transform: translateY(-50%);
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
}
.edit-password-box .website-icon {
  user-select: none;
  -webkit-user-drag: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  width: 96px;
  height: 96px;
}
.edit-password-box #no-img-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #54c2f0;
}
.edit-password-box #no-img-box p {
  color: white;
  font-size: 45px;
  font-weight: 900;
}
.edit-password-box .close {
  outline: none;
  background: transparent;
  border: none;
  position: absolute;
  top: 10px;
  left: 20px;
  transition: all 200ms;
  font-size: 45px;
  font-weight: bold;
  text-decoration: none;
  color: var(--text-theme);
}
.edit-password-box .close:hover {
  cursor: pointer;
}
.edit-password-box .content {
  max-height: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
}
.edit-password-box .fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.edit-password-box .fields > .field {
  display: flex;
  flex-direction: column;
  border: 1px var(--field-bg-theme) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Open Sans", sans-serif;

  width: 350px;
  height: 35px;
  background-color: var(--field-bg-theme);
  border-radius: 5px;
}
.edit-password-box .field i {
  margin-right: 5px;
  margin-left: 5px;
}
.edit-password-box .field i:hover {
  cursor: pointer;
}
.edit-password-box .fields input {
  width: 100%;
  height: 100%;
  border: none;
  color: var(--text-theme);

  outline: none;
  font-size: 17px;
  background: transparent;
  text-align: center;
}

.edit-password-box > .fields > .field-name {
  margin-right: 10px;
  font-weight: 600;
}
.edit-password-box .field .generate-password-btn {
  margin-left: 5px;
  margin-top: 2px;
}
.edit-password-box .field .generate-password-btn:hover {
  cursor: pointer;
}

.edit-password-box .buttons {
  display: flex;
  flex-direction: row;
}
.edit-password-box .save-password-button {
  border: none;
  margin-top: 20px;
  outline: none;
  color: white;
  padding: 8px 15px 8px 15px;
  background-color: #54c2f0;
  border-radius: 5px;
  font-size: 20px;
  transition: background-color 0.5s;
}
.edit-password-box .delete-button {
  border: 1px solid #e1e1e1;
  margin-top: 20px;
  outline: none;
  color: var(--text-theme);
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
  font-size: 20px;
  background-color: var(--bg-theme);
  margin-left: 10px;
  transition: background-color 0.5s;
}
.edit-password-box .cancel-button {
  border: 1px solid #e1e1e1;
  margin-top: 20px;
  outline: none;
  color: var(--text-theme);
  padding: 8px 15px 8px 15px;
  border-radius: 5px;
  font-size: 20px;
  margin-left: 50px;
  background-color: var(--bg-theme);

  transition: background-color 0.5s;
}
.edit-password-box .cancel-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s;
  cursor: pointer;
}
.edit-password-box .save-password-button:hover {
  background-color: #54aaf0;
  transition: background-color 0.5s;
  cursor: pointer;
}

.edit-password-box .delete-button:hover {
  background-color: #e9402c;
  transition: background-color 0.5s;
  cursor: pointer;
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  right: 0;
  left: 15px;
  top: -150px;
  background-color: var(--bg-dropdown-theme);
  width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.gen-password-attributes-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gen-password-attributes-box > .gen-box1 > .gen-attribute,
.gen-password-attributes-box > .gen-box2 > .gen-attribute {
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  cursor: pointer;
  user-select: none;
  font-weight: 600;
  margin-top: 0;
}
.gen-password-length-box {
  text-align: center;
  margin-bottom: 0;
}
.gen-password-length-box > p {
  font-weight: 600;
  margin-bottom: 0;
}
.gen-password-length-box > .MuiSlider-root {
  color: #54c2f0;
  height: 8px;
  width: 200px;
}
.gen-password-length-box > .MuiSlider-root .MuiSlider-thumb {
  height: 24px;
  width: 24px;
  background-color: var(--bg-theme);
  border: 2px solid currentColor;
  margin-top: -10px;
  margin-left: -12px;
}
.gen-password-length-box > .MuiSlider-root .MuiSlider-thumb:focus,
.gen-password-length-box > .MuiSlider-root .MuiSlider-thumb:hover,
.password-length-box > .MuiSlider-root .MuiSlider-thumb:active {
  box-shadow: inherit;
}
.gen-password-length-box > .MuiSlider-root .MuiSlider-valueLabel {
  left: calc(-50% + 4px);
}
.gen-password-length-box > .MuiSlider-root .MuiSlider-track {
  height: 4px;
  border-radius: 4px;
}
.gen-password-length-box > .MuiSlider-root .MuiSlider-rail {
  height: 4px;
  border-radius: 4px;
}
.dropdown-content > .gen-button {
  border: none;
  margin-top: 20px;
  outline: none;
  color: white;
  padding: 6px 12px 6px 12px;
  background-color: #54c2f0;
  border-radius: 5px;
  font-size: 20px;
  transition: background-color 0.5s;
  margin-top: 7px;

  margin-bottom: 4px;
}
.dropdown-content > .gen-button:hover {
  background-color: #54aaf0;
  transition: background-color 0.5s;
  cursor: pointer;
}
@media all and (max-width: 1053px) {
  .box {
    width: 70%;
  }
  .edit-password-box {
    width: 70%;
  }
}
@media all and (max-width: 524px) {
  .box {
    width: 95%;
  }
  .edit-password-box {
    width: 95%;
  }
  .edit-password-box .close {
    left: 0;
    top: 0;
  }
  .edit-password-box .content > .fields > .field {
    width: 280px;
    height: 30px;
  }
  .edit-password-box .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .edit-password-box .cancel-button {
    margin-left: 0px;
  }
}
@media all and (max-width: 381px) {
  .dropdown-content {
    width: 260px;
  }
}
