
.my-passwords {
    display: flex;
    font-family: "Open Sans",sans-serif;
    color: var(--text-theme);
    background-color: var(--bg-theme);
}
#swal2-title,#swal2-content {
    color: var(--text-theme);
}
.passwords-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.passwords-content > nav {
    display: flex;
    flex-direction: row;
    
    margin-top: 20px;
    width: 100%;
}
#add-password-button {
    font-family: "Open Sans",sans-serif;

    margin-left: 20px;
    outline: none;
    border: none;
    color: white;
    padding: 10px 20px 10px 20px;
    background-color: #54c2f0;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    transition: background-color 0.5s;
}
#add-password-button:hover {
    background-color: #54AAF0;
    transition: background-color 0.5s;
    cursor: pointer;
}
.passwords-content > nav > .search-bar {
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin-left: 150px;

    background-color: rgba(177, 177, 177, 0.2);
    border-radius: 15px;
    
}
.passwords-content > nav > .search-bar > i{ 
    margin-left: 5px ;
}
.passwords-content > nav > .search-bar > i:hover{
    cursor: pointer;
}
.passwords-content > nav > .search-bar > input{
    font-family: "Open Sans",sans-serif;
    outline: none;
    font-size: 20px;
    text-align: center;
    background: transparent;
    border: none;   
    width: 300px;
    height: 35px;
}
.passwords-content .sort {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    margin-top: 15px;
    margin-left: 25px;
}
.passwords-content .sort #sort-select-label {
    margin-right: 15px;
    color: var(--text-theme);
}
.passwords-content .sort #sort-select {
    color: var(--text-theme);
}

.password-list {
    overflow-y: scroll;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    height: 100%;
}
.no-passwords  {
    text-align: center;
}
.no-passwords > i{
    color: #E0E0E0;
    font-size: 100px;
}
@media all and (max-width: 690px ) {
    .passwords-content > nav > .search-bar {
        margin-left: 10px;
    }

    .passwords-content > nav {
        flex-direction: column;
    }
    #add-password-button {
        width: 150px;
        justify-self: center;
        align-self: center;
        margin-bottom: 10px;
    }
    .passwords-content > nav > .search-bar > input{
        width: 250px;
    }

    .passwords-content .sort {
        align-self: center;
    }
}