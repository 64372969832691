body > #root > .email-confirmed {
    height: 100vh;
    background-color: var(--blue-bg-theme);
}

.email-confirmed {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Open Sans",sans-serif;
}
.email-confirmed > .box {
    display: flex;
    background-color: var(--bg-theme);
    border-radius: 15px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 500px;
    height: 400px;
    -webkit-box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.2);
    
}
.email-confirmed  h2, .email-confirmed p {
    color: var(--text-theme)
}
.email-confirmed  .resend-email {
    border: none;
    background: transparent;
    outline: none;
    color: #045CF0;
    font-size: 15px;
}
.email-confirmed .resend-email:hover {
    cursor: pointer;
}
.email-confirmed .resend-email:disabled {
    color: #B3B3B3;
}
.email-confirmed  .resend-email:disabled:hover  {
    cursor: not-allowed;
}
.email-confirmed  .icon { 
    user-select: none;
    -webkit-user-drag: none;
}

.email-confirmed  .continue-btn,.email-confirmed .login-btn {
    cursor: pointer;
    border: 2px #54c2f0 solid;
    border-radius: 5px;
    background-color: var(--bg-theme);
    color:#54c2f0;
    font-size: 20px;
    font-weight: 600;
    padding: 10px ;
    transition: background-color 0.2s;
    width: 250px;
    outline: none;
    margin-top: 10px;
    text-decoration: none;
    
}


.email-confirmed .continue-btn:hover,.email-confirmed .login-btn:hover { 
    background-color: rgba(223,223,223,0.2);
    transition: background-color 0.2s;
}

@media all and (max-width: 519px) {
    .email-confirmed > .box {
        width: 95%;
    }
    .email-confirmed > .box > h2{
        font-size: 18px;
    }
    .email-confirmed > .box > p{
        font-size: 14px;
    }
    .email-confirmed .continue-btn,.email-confirmed .login-btn {
        width: 60%;
    }
}

